import { useState, useEffect } from 'react';

import './Home.css';

import 'react-toastify/dist/ReactToastify.css';

import { useNavigate } from 'react-router-dom';

import { motion } from "framer-motion";

function Home() {
  const navigate = useNavigate();
  const [step, setStep] = useState(1);

  useEffect(() => {
    const fadeFirst = document.querySelector('.fade-first');
    const fadeSecond = document.querySelector('.fade-second');

    const fadeIn = (element) => {
      element.style.opacity = '1';
    };

    const fadeOut = (element) => {
      element.style.opacity = '0';
    };

    const startAnimation = () => {
        switch (step) {
          case 1:
            fadeIn(fadeFirst);
            setTimeout(() => setStep(2), 500);
            break;
          case 2:
            fadeIn(fadeSecond);
            setTimeout(() => setStep(3), 500);
            break;
          case 3:
            setTimeout(() => setStep(4), 1000);
            break;
          case 4:
            fadeOut(fadeFirst);
            fadeOut(fadeSecond);
            setTimeout(() => setStep(5), 500);
            break;
          case 5:
            setTimeout(() => setStep(1), 750);
            break;
          default:
            break;
        }
      };
      startAnimation();
    }, [step]);

  return (
    <>
      <motion.div
        className="flex w-full min-h-screen relative"
      >
        <div className="absolute inset-0 bg-no-repeat bg-top bg-cover" style={ { backgroundImage: 'url(/images/background1.jpg)' } }></div>
        <div className="absolute inset-0 bg-no-repeat bg-top bg-cover fade-element fade-first" style={ { backgroundImage: 'url(/images/background2.jpg)' } }></div>
        <div className="absolute inset-0 bg-no-repeat bg-top bg-cover fade-element fade-second" style={ { backgroundImage: 'url(/images/background3.jpg)' } }></div>
        
        <div className="flex items-center w-full flex-col z-10">
          <div className='px-4'>
            <img alt="" className="h-12 mt-6 object-contain" src="/images/logo.png" />
            <p className='text-white font-light text-2xl mt-[8px] text-center select-none'>Linkedin Profile Visibility Score</p>
          </div>
          <div className="flex flex-col justify-end flex-1 w-full">
            <div className="bg-white/[.1] backdrop-blur-[2px] text-white font-semibold text-center py-5 text-[40px] leading-[80px] mt-16">
              <p>¿Quieres conocer tu visibilidad en LinkedIn?</p>
              <p>Nosotros analizamos tu perfil y puntuamos tu visibilidad :)</p>
            </div>
            <div className="flex w-full justify-center my-16 min-h-[80px] items-center">
              <button type="button" className="text-primary hover:text-white bg-secondary text-2xl leading-6 hover:leading-8 font-medium p-4 hover:p-6 rounded-[10px] transition-all" onClick={() => navigate('/start')} >Analiza tu perfil</button>
            </div>
          </div>
        </div>
      </motion.div>
    </>
  );
}

export default Home;
