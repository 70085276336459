import 'react-toastify/dist/ReactToastify.css';

import { motion } from "framer-motion";
import { useNavigate } from 'react-router-dom';

function FormStart() {
    const navigate = useNavigate();

  return (
    <>
    <motion.div
      className="flex w-full min-h-screen relative"
      initial={{ x: window.innerWidth }}
      animate={{ x: 0, transition: { duration: 0.5 } }}
    >
            <div className="w-[24px] md:w-[33.33%] bg-no-repeat bg-top bg-cover" style={ { backgroundImage: 'url(/images/hello.jpg)' } }></div>
            <div className="flex flex-1 bg-[#090909]">
                <div className="flex flex-col justify-center items-center flex-1 bg-white rounded-l-3xl p-[34px]">
                    <div className="flex flex-1 flex-col justify-center items-center max-w-[722px]">
                        <p className="text-primary text-[32px] leading-[48px] text-center">Ahora necesitamos que respondas un breve cuestionario y te enviaremos la visibilidad que tienes en LinkedIn por email.</p>
                        
                        <div className="flex w-full justify-center mt-[72px] min-h-[80px] items-center">
                            <button type="button" className="text-primary hover:text-white bg-secondary text-2xl leading-6 hover:leading-8 font-medium p-4 hover:p-6 rounded-[10px] transition-all" onClick={() => navigate('/form')}>¡Empezamos!</button>
                        </div>

                        <img alt="" src="/icons/form-start-icon.svg" className="mt-[68px]" />
                    </div>
                    
                    <div className="flex w-full justify-end">
                        <img alt="" src="/icons/logo.svg" />
                    </div>
                </div>
            </div>
      </motion.div>
    </>
  );
}

export default FormStart;
