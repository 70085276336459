import { useForm } from "react-hook-form"
import { useState, useEffect } from 'react';
import * as React from "react";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import './Form.css';

import { motion } from "framer-motion";
import { useNavigate } from 'react-router-dom';

function Form() {
  const navigate = useNavigate();

  const [paths, setPaths] = useState([
    { field: 'name', value: '', question: 'Primero, ¿Puedes decirnos tu nombre?*' },
    { field: 'lastname', value: '', question: '¿Y tus apellidos?*' },
    { field: 'company', value: '', question: '¡Genial! Ahora, ¿de qué empresa vienes?*' },
    { field: 'position', value: '', question: '¿Qué cargo tienes?*' },
    { field: 'email', value: '', question: '¡Solo un par de preguntas antes de terminar! ¿Cuál es tu correo electrónico?*' },
    { field: 'linkedin', value: '', question: '¿Y tu LinkedIn?*' },
  ]);
  const [path, setPath] = useState(0);
  const [isDisabled, setDisabled] = useState(true);
  const [loading, setLoading] = useState(false);
  const [prev, setPrev] = useState(false);
  const [next, setNext] = useState(false);

  const { register, handleSubmit, watch, setFocus, formState: { errors }, } = useForm()

  document.body.style.backgroundColor = '#315117';

  const nextPath = () => {
    if (path < 5) {
      const newPaths = JSON.parse(JSON.stringify(paths))
      newPaths[path].value = watch(paths[path].field)
      setPaths(newPaths)

      setPath(path + 1)
    }
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      if (!isDisabled) {
        nextPath()
      }
    }
  };

  const onSubmit = (data) => {
    setLoading(true)

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          name: data.name,
          lastname: data.lastname,
          company: data.company,
          position: data.position,
          email: data.email,
          linkedin_url: data.linkedin,
        })
    };
    
    fetch('http://164.92.185.245:5003/api/score', requestOptions)
        .then(response => response.json())
        .then(response_data => {
          if (response_data.status) {
            setLoading(false)
            navigate('/end')
          } else {
            setLoading(false)
            toast.error(response_data.error_message, { position: toast.POSITION.TOP_RIGHT })
          }
        });
  }

  useEffect(() => {
    setFocus(paths[path].field)
    setTimeout(() => {
      window.scrollTo({ top: 0 })
    }, 50);
    setPrev(path > 0 && paths[path-1].value !== null && paths[path-1].value !== '')
    setNext(path < 5 && paths[path].value !== null && paths[path].value !== '')
    }, [path, paths, setFocus]);

  useEffect(() => {
    const validField = (value, field) => {
      let is_invalid = value === null || value === ''
      if (!is_invalid) {
        if (field === 'email') {
          is_invalid = !(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(value))
        } else if (field === 'linkedin') {
          is_invalid = !(/^https:\/\/www\.linkedin\.com\/in\/[a-zA-Z0-9-%]+\/?$/.test(value))
        }
      }
      return is_invalid
    }

    let newIsDisabled = validField(watch(paths[path].field), paths[path].field)
    if (!newIsDisabled && paths[path].field === "linkedin") newIsDisabled = validField(watch("linkedin_check") ? 'on' : '', "linkedin_check")
    if (!newIsDisabled && paths[path].field === "linkedin") newIsDisabled = validField(watch("privacy_policy") ? 'on' : '', "privacy_policy")
    setDisabled(newIsDisabled)

    const subscription = watch((value) => {
      let newIsDisabledSub = validField(value[paths[path].field], paths[path].field)
      if (!newIsDisabledSub && paths[path].field === "linkedin") newIsDisabledSub = validField(value["linkedin_check"] ? 'on' : '', "linkedin_check")
      if (!newIsDisabledSub && paths[path].field === "linkedin") newIsDisabledSub = validField(value["privacy_policy"] ? 'on' : '', "privacy_policy")
      setDisabled(newIsDisabledSub)
    })
    return () => subscription.unsubscribe()
    }, [watch, path, paths, errors]);

  return (
    <>
                          <form onSubmit={handleSubmit(onSubmit)}>
    <motion.div
      className="flex w-full min-h-screen relative"
      initial={{ x: window.innerWidth }}
      animate={{ x: 0, transition: { duration: 0.5 } }}
      exit={{ x: window.innerWidth, transition: { duration: 0.1 } }}
    >
            <div className="hidden md:block md:w-[33.33%] bg-no-repeat bg-top bg-cover" style={ { backgroundImage: 'url(/images/form.jpg)' } }></div>
            <div className="flex flex-1 bg-[#315117]">
                <div className="flex flex-col justify-center items-center flex-1 bg-white p-[34px] border-y-[24px] border-l-[24px] border-[#315117] box-border">
                    <div className="flex flex-1 flex-col justify-center items-center max-w-[722px]">
                        <div className="flex w-full md:min-h-[160px] text-primary items-start justify-start gap-[16px] select-none">
                          <span className="text-[56px] leading-[84px] font-bold">{path+1}-</span>
                          <span className="text-[32px] leading-[48px] font-medium pt-[16px] break-words">{ paths[path].question }</span>
                        </div>
                        <div className="flex w-full justify-start md:pl-[47px] mt-[24px]">
                            <div className="border-b pb-[8px] border-primary" style={ { display: path === 0 ? 'block': 'none' } }>
                              <input className="outline-0 w-full md:w-auto md:min-w-[387px] font-normal text-primary text-2xl leading-[24px]" autoComplete="off" readOnly={loading} maxLength={191} defaultValue="" {...register("name")} placeholder="Escribe aquí tu respuesta..." onKeyDown={handleKeyDown} />
                            </div>
                            <div className="border-b pb-[8px] border-primary" style={ { display: path === 1 ? 'block': 'none' } }>
                              <input className="outline-0 w-full md:w-auto md:min-w-[387px] font-normal text-primary text-2xl leading-[24px]" autoComplete="off" readOnly={loading} maxLength={191} defaultValue="" {...register("lastname")} placeholder="Escribe aquí tu respuesta..." onKeyDown={handleKeyDown} />
                            </div>
                            <div className="border-b pb-[8px] border-primary" style={ { display: path === 2 ? 'block': 'none' } }>
                              <input className="outline-0 w-full md:w-auto md:min-w-[387px] font-normal text-primary text-2xl leading-[24px]" autoComplete="off" readOnly={loading} maxLength={191} defaultValue="" {...register("company")} placeholder="Escribe aquí tu respuesta..." onKeyDown={handleKeyDown} />
                            </div>
                            <div className="border-b pb-[8px] border-primary" style={ { display: path === 3 ? 'block': 'none' } }>
                              <input className="outline-0 w-full md:w-auto md:min-w-[387px] font-normal text-primary text-2xl leading-[24px]" autoComplete="off" readOnly={loading} maxLength={191} defaultValue="" {...register("position")} placeholder="Escribe aquí tu respuesta..." onKeyDown={handleKeyDown} />
                            </div>
                            <div className="border-b pb-[8px] border-primary" style={ { display: path === 4 ? 'block': 'none' } }>
                              <input className="outline-0 w-full md:w-auto md:min-w-[387px] font-normal text-primary text-2xl leading-[24px]" autoComplete="off" readOnly={loading} maxLength={191} type="email" defaultValue="" {...register("email")} placeholder="Escribe aquí tu respuesta..." onKeyDown={handleKeyDown} />
                            </div>
                            <div className="border-b pb-[8px] border-primary" style={ { display: path === 5 ? 'block': 'none' } }>
                              <input className="outline-0 w-full md:w-auto md:min-w-[387px] font-normal text-primary text-2xl leading-[24px]" autoComplete="off" readOnly={loading} maxLength={191} {...register("linkedin")} placeholder="Escribe aquí tu respuesta..." />
                            </div>
                        </div>
                        <div className="w-full relative justify-start items-center md:pl-[47px] mt-[32px] gap-2 select-none" style={ { display: path === 5 ? 'flex': 'none' } }>
                          <input type="checkbox" id="linkedin_verify" className="relative peer appearance-none w-4 h-4 border-2 border-primary rounded-sm bg-white cursor-pointer checked:bg-primary" defaultValue="" {...register("linkedin_check") } onKeyDown={handleKeyDown} />
                          <label htmlFor="linkedin_verify" className="cursor-default font-normal text-primary text-lg">Confirmo que soy el propietario de este perfil de linkedin</label>
                            <svg
                              className="
                                absolute 
                                w-3 h-3
                                ml-0.5
                                hidden peer-checked:block
                                "
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="#FFFFFF"
                              strokeWidth="4"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                          >
                            <polyline points="20 6 9 17 4 12"></polyline>
                          </svg>
                        </div>
                        <div className="w-full relative justify-start items-center md:pl-[47px] mt-[16px] gap-2 select-none" style={ { display: path === 5 ? 'flex': 'none' } }>
                          <input type="checkbox" id="privacy_policy" className="relative peer appearance-none w-4 h-4 border-2 border-primary rounded-sm bg-white cursor-pointer checked:bg-primary" defaultValue="" {...register("privacy_policy") } onKeyDown={handleKeyDown} />
                          <label htmlFor="privacy_policy" className="cursor-default font-normal text-primary text-lg">He leído y acepto la <a href="#" className="underline font-medium text-[#00819D]">política de privacidad</a>.</label>
                            <svg
                              className="
                                absolute 
                                w-3 h-3
                                ml-0.5
                                hidden peer-checked:block
                                "
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="#FFFFFF"
                              strokeWidth="4"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                          >
                            <polyline points="20 6 9 17 4 12"></polyline>
                          </svg>
                        </div>
                        <div className="flex flex-col md:flex-row w-full justify-start mt-[84px] min-h-[80px] items-center gap-[33px]">
                          <div className="w-[161.14px]">
                            <button type="button" className="text-primary hover:text-white disabled:text-primary bg-secondary text-2xl leading-6 hover:leading-8 disabled:leading-6 font-medium p-4 hover:p-6 disabled:p-4 rounded-[10px] transition-all disabled:opacity-20 select-none" disabled={isDisabled} onClick={nextPath} style={ { display: path < paths.length - 1 ? 'block' : 'none' } } >Siguiente</button>
                            <button type="submit" className="text-primary hover:text-white disabled:text-primary bg-secondary text-2xl leading-6 hover:leading-8 disabled:leading-6 font-medium p-4 hover:p-6 disabled:p-4 rounded-[10px] transition-all disabled:opacity-20 select-none" disabled={isDisabled || loading} style={ { display: path === paths.length - 1 ? 'block' : 'none' } }>{ loading ? 'Enviando' : 'Enviar' }</button>
                          </div>
                          
                          <div className="flex gap-[16px]">
                            <div className="w-[32px] h-[32px]">
                              <button type="button" className="select-none" style={{ display: prev && !loading ? 'block' : 'none' }} onClick={() => setPath(path - 1)}>
                                <img alt="" src="/icons/back.svg" />
                              </button>
                            </div>
                            <div className="w-[32px] h-[32px]">
                              <button type="button" className="select-none" style={{ display: next && !loading ? 'block' : 'none' }} onClick={() => setPath(path + 1)}>
                                <img alt="" src="/icons/next.svg" />
                              </button>
                            </div>
                          </div>
                        </div>
                      <div className="flex w-full select-none min-h-[250px] mt-[82px] mb-[24px]">
                        <img alt="" src={ `/icons/step-${ path+1 }.svg` } />
                      </div>
                    </div>
                    
                    <div className="flex w-full justify-end">
                        <img alt="" src="/icons/logo.svg" />
                    </div>
                </div>
            </div>
          <ToastContainer />
      </motion.div>
      </form>
    </>
  );
}

export default Form;
